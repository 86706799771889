import React from 'react';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as styles from './Hero.module.scss';
import { graphql, useStaticQuery } from 'gatsby';
import video from '../../images/herovid.mp4';

export interface HeroProps {
    imageName: string;
    children: any;
    isIndex?: boolean;
}

const Hero = ({ imageName, children, isIndex }: HeroProps) => {


    const query = useStaticQuery(graphql`
        query {
            indexBackground: file(relativePath: {eq: "pages/index/background.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            aboutBackground: file(relativePath: { eq: "pages/about/background.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            servicesBackground: file(relativePath: { eq: "pages/services/background.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            ccasBackground: file(relativePath: { eq: "pages/services/ccas/ccasBackground2.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            mmBackground: file(relativePath: { eq: "pages/services/maturitymodel/mmbackground.png"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            partnersBackground: file(relativePath: { eq: "pages/partners/background.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            qmsBackground: file(relativePath : { eq: "pages/services/qms/qmsbackground.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            ccaspspsBackground: file(relativePath : { eq: "pages/services/ccaspsps/CCAS21.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            carbonoptimisationBackground: file(relativePath : { eq: "pages/services/carbonoptimisation.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
            contactBackground: file(relativePath : { eq: "pages/contact/background.jpg"}) {
                childImageSharp {
                    gatsbyImageData(
                        height: 600
                        quality: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                }
            }
        }
    `)

    return <div className={styles.container}>
    { isIndex ? (
                <video
                className={styles.image}
                width="100%"
                loop
                muted
                autoPlay
              >
                <source
                  src={video}
                  type="video/mp4"
                />
              </video>
    ) : (<GatsbyImage
        className={styles.image}
        alt=""
        image={query[imageName].childImageSharp.gatsbyImageData}
      />) }
      <div className={styles.overlay}>
          <div className={styles.overlayInner}>
            {children}
          </div>
      </div>
    </div>
}

export default Hero;
