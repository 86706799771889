import React from "react";
import AccentBackground from "../components/AccentBackground";
import AccentBar from "../components/AccentBar";
import Hero from "../components/Hero";
import IntroBlock from "../components/IntroBlock";
import LatestBlogPosts from "../components/LatestBlogPosts";
import SEO from "../components/SEO";
import Layout from "../Layout";

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About TBL" />

      <Hero imageName="aboutBackground">
        <AccentBar>
          <h1>
            At the forefront <br />
            of climate change <br />
            and sustainability <br />
            action
          </h1>
        </AccentBar>
      </Hero>

      <IntroBlock>
        <h1>About</h1>
        <p>
          TBL (Triple Bottom Line LTD) is a consultancy practice providing
          climate change and carbon market advisory services. TBL was founded by
          Kevin Williams in 2014. We have a track record of working with both
          private and public sector organisations across a range of sectors,
          providing practical, commercial insights in order to achieve specific
          policy and commercial objectives.
        </p>
      </IntroBlock>

      <AccentBackground>
        <div className="text-center">
          <h2>
            The team has extensive experience of working on complex,
            multi-jurisdictional projects, managing multiple work streams and
            drawing on their knowledge of global carbon markets to provide
            maximum value for their clients.
          </h2>
        </div>
      </AccentBackground>

      <section>
        <div className="text-center">
          <p>
            Our team has a broad range of specialisms, including carbon and
            energy commodities markets, tax, accounting, regulatory, risk
            management, climate change policy and emissions allowance
            registries. This enables us to provide a comprehensive suite of
            services to our clients that we can tailor to best meet their needs.
            In addition to delivering work, our team have been actively involved
            in the development of climate change policy and carbon markets,
            participating in a number of industry bodies and speaking regularly
            at conferences. We are dedicated to providing insight and value in
            all that we do, working collaboratively with our clients to achieve
            their goals and to support the continued development of local and
            international climate change policy and market-based solutions to
            climate change.
          </p>
        </div>
      </section>

      <section>
        <div className="text-center">
          <h2>Latest Research</h2>
          <LatestBlogPosts />
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;
